import { Grid, Stack } from "@mantine/core";
import SkillGroup, { SkillListItem } from "src/components/SkillGroup";
// import classes from "./Skills.module.css";

const Functional: SkillListItem[] = [
  { name: "Typescript", rating: 5 },
  { name: "HTML/CSS", rating: 4 },
  { name: "C#", rating: 3 },
  { name: "Python", rating: 3 },
  { name: "Java", rating: 2 },
];
const Querying: SkillListItem[] = [
  { name: "GraphQL", rating: 5 },
  { name: "SQL", rating: 4 },
  { name: "TypeORM", rating: 4 },
  { name: "Prisma", rating: 3 },
  { name: "MongoDB", rating: 2 },
];
const Libraries: SkillListItem[] = [
  { name: "React", rating: 5 },
  { name: "React-Native", rating: 4 },
  { name: "Node.js", rating: 4 },
  { name: "Mantine", rating: 4 },
  { name: "Express", rating: 3 },
];
const Cloud: SkillListItem[] = [
  { name: "Azure DevOps", rating: 5 },
  { name: "Azure Cloud", rating: 4 },
  { name: "Docker", rating: 4 },
  { name: "Android Studio", rating: 3 },
  { name: "Xcode", rating: 3 },
  { name: "AWS Cloud", rating: 2 },
];

const Skills = () => {
  return (
    <Grid gutter={"xl"}>
      <Grid.Col span={{ base: 12, md: 6 }}>
        <Stack gap="md">
          <SkillGroup groupLabel="Languages" skills={Functional} />
          <SkillGroup groupLabel="Query" skills={Querying} />
        </Stack>
      </Grid.Col>
      <Grid.Col span={{ base: 12, md: 6 }}>
        <Stack gap="md">
          <SkillGroup groupLabel="Libraries" skills={Libraries} />
          <SkillGroup groupLabel="DevOps & Pipeline" skills={Cloud} />
        </Stack>
      </Grid.Col>
    </Grid>
  );
};

export default Skills;
